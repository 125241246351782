<template>
  <v-container>
    <v-card class="pa-0" :loading="description_loading">
      <v-card-title> Who We Are </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
          <v-row class="mt-0">
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta Title"
              v-model="meta_data.meta.title"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta description"
              v-model="meta_data.meta.description"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          label="Title"
          v-model="meta_data.title"
          outlined
          dense
        ></v-text-field>

        <p class="font-weight-bold">Description</p>
        <vue-editor v-model="meta_data.description" :editorToolbar="customToolbar"> </vue-editor>
        <v-btn
          class="mr-4 mt-2"
          color="secondary"
          @click="updateMetaPage()"
          :loading="description_loading"
          :disabled="description_loading"
          >submit</v-btn
        >
      </v-card-text>
    </v-card>

    <!-- our team -->
    <!-- <v-card class="pa-0 mt-4" :loading="team_description_loading">
      <v-card-title>Team</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta Title"
              v-model="team_meta_data.meta.title"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              label="Meta description"
              v-model="team_meta_data.meta.description"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          label="Title"
          v-model="team_meta_data.title"
          outlined
          dense
        ></v-text-field>

        <p class="font-weight-bold">Team Description</p>
        <vue-editor
          v-model="team_meta_data.description"
          :editorToolbar="customToolbar"
        >
        </vue-editor>
        <v-btn
          class="mr-4 mt-2"
          color="secondary"
          @click="updateTeamMetaPage()"
          :loading="team_description_loading"
          :disabled="team_description_loading"
          >submit</v-btn
        >
      </v-card-text>
    </v-card> -->
    <v-card class="pa-0 mt-3">
      <v-card-title>
        Team Member List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="team_search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:300ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="team_headers"
        :search="team_search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        :loading="loading"
        item-key="id"
        class="elevation-1 page__table"
        :show-select="false"
        :disable-pagination="true"
        :hide-default-footer="true"
      >
         <template v-slot:body="">
          <draggable
            :list="items.results"
            v-bind="dragOptions"
            tag="tbody"
            @change="changeOrder"
          >
            <tr
              v-for="(member, index) in items.results"
              :key="index"
              style="cursor: move !important;"
            >
              <td>
                <v-icon style="cursor: pointer;" title="Drag">
                  mdi-drag 
                </v-icon>
              </td>
              <td>
                {{ member.name }}
              </td>
              <td>
                {{ member.designation ? member.designation : "N/A" }}
              </td>
              <td>
                {{ member.about ? member.about : "N/A" }}
              </td>
              <td>
                <v-btn
                  color="grey darken-2 px-2"
                  small
                  dark
                  @click="showPreview(member)"
                >
                  <v-icon class="pr-2">mdi-image-area</v-icon>View
                </v-btn>
              </td>
              <td>
                <v-btn
                  color="grey darken-2"
                  icon
                  dark
                  :to="'/team_member/edit/' + member.id"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="grey darken-2" icon dark @click="confirmDelete(member)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="team_dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"
          >Are you sure delete <b>{{ team.name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="team_dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(team)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" fixed fab bottom right to="/team_member/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="show_preview" max-width="500" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <iframe
            :src="preview_data"
            frameborder="0"
            width="100%"
            style="max-height: 500px"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- our team end -->
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import { parseParams } from "../../plugins/helper";
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";

export default {
  name: "WhoWeAre",
  mounted() {
    this.getMetaPageDetails();
    this.getItems();
    // this.getTeamMetaPageDetails();
  },
  components: {
    VueEditor,
    draggable
  },
  data() {
    return {
      loading: false,
      description_loading: false,
      dialog: false,
      meta_data: {
         meta: {
          title: "",
          description: "",
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],        
        [{ list: "ordered" }, { list: "bullet" }],        
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]        
      ],
      team_dialog: false,
      team_description_loading: false,
      team: {
        name: null,
      },
      team_meta_data: {
        meta: {
          title: "",
          description: "",
        },
      },
      show_preview: false,
      team_search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      pagination: {
        itemsPerPage: -1,
        sortBy: ["order"],
        page: 1,
        filters: {},
      },
      team_headers: [
        { text: '', value: '', sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Designation", value: "designation", sortable: false },
        { text: "About", value: "about", sortable: false },
        { text: "Profile Image", value: "profile_image", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.team_search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "company",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateMetaPage() {
      let _self = this;
      this.description_loading = true;
      this.$axios
        .put(`admin/metapage/edit/${this.meta_data.id}`, this.meta_data)
        .then((response) => {
          if (response.data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/whoweare");
          }
          _self.description_loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.description_loading = false;
        });
    },
    getMetaPageDetails() {
      let _self = this;
      this.description_loading = true;
      this.$axios
        .get("/admin/metapages/WHOWEARE")
        .then((response) => {
          _self.meta_data = response.data.data;
          var doc = new DOMParser().parseFromString(
            _self.meta_data.description,
            "text/html"
          );
          _self.meta_data.description = doc.documentElement.textContent;
          _self.description_loading = false;
        })
        .catch(function () {
          _self.description_loading = false;
        });
    },
    // updateTeamMetaPage() {
    //   let _self = this;
    //   this.team_description_loading = "secondary";
    //   this.$axios
    //     .put(`admin/metapage/edit/${this.team_meta_data.id}`, this.team_meta_data)
    //     .then((response) => {
    //       if (response.data.status) {
    //         _self.setAlert({
    //           show: true,
    //           variant: "success",
    //           message: response.data.message,
    //           dismissCountDown: 5000,
    //         });
    //         _self.$router.push("/whoweare");
    //       }
    //       _self.team_description_loading = false;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       _self.team_description_loading = false;
    //     });
    // },
    showPreview(item) {
      this.preview_data = item.profile_pic;
      this.show_preview = true;
    },
    // getTeamMetaPageDetails() {
    //   let _self = this;
    //   this.team_description_loading = true;
    //   this.$axios
    //     .get("/admin/metapages/TEAM")
    //     .then((response) => {
    //       _self.team_meta_data = response.data.data;
    //       var doc = new DOMParser().parseFromString(
    //         _self.team_meta_data.description,
    //         "text/html"
    //       );
    //       _self.team_meta_data.description = doc.documentElement.textContent;
    //       _self.team_description_loading = false;
    //     })
    //     .catch(function () {
    //       _self.team_description_loading = false;
    //     });
    // },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get("/admin/team_member/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.team_dialog = true;
      this.team = item;
    },
    deleteItem(item) {
      const index = this.items.results.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios
        .delete("/admin/team_member/delete/" + item.id)
        .then((res) => {
          if (res.status) {
            _self.items.results.splice(index, 1);
            _self.team_dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    changeOrder() {
      let _self = this;
      _self.loading = true;

      let new_order = [];

      for(let i=0; i<_self.items.results.length; i++) {
        new_order.push({
          index: i,
          id: _self.items.results[i].id
        });
      }

      this.$axios
        .post("/admin/team_member/change_order", {
          new_order: new_order
        })
        .then((res) => {
          if (res.status) {
           res 
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    }
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 1;
  background: #dadada80 !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff;
}
</style>